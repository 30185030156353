
@keyframes progressAnimation {
    0% { width: 0% }
    20% { width: 20% }
    40% { width: 40% }
    60% { width: 60% }
    80% { width: 80% }
    100% { width: 100% }
}
.linearProgress {
    background-color: #111111;
    animation: progressAnimation 2s linear infinite;
}