.analysis {
  height: auto;
  flex-grow: 1;
  margin-bottom: 56px;
}

.analysis-total-box {
  width: calc((100% - 64px) / 5);
}

.analysis-summary-list {
  height: 390px;
  overflow-y: scroll;
  overflow-x: unset;
}

.analysis-summary-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.analysis-summary-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.quick-access-div {
  padding: 24px 16px;
  width: 33.3%;
  min-height: 180px;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
}

.quick-access-div:hover {
  background: #f7f7f7;
}

.quick-access-img {
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid var(--stroke-soft-200, #f3f3f3);
  background: var(--bg-white-0, #fff);

  /* regular-shadow/small */
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}
