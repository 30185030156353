::-webkit-scrollbar{
    width:0px;
    background-color: #F5F5F5;
    border-radius: 999px;
}
::-webkit-scrollbar-thumb {
    background-color: #E7E7E7;
    border-radius: 999px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #DDDDDD;
}
@-moz-document url-prefix() {
    *
{
    
    scrollbar-width: thin;
    scrollbar-color: #E7E7E7 #F5F5F5;
}
}