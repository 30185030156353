.takedown-album-div {
  padding: 8px;
  gap: 16px;
  border: 0px 0px 1px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--stroke-soft-200, #F3F3F3)
}

.takedown-album-div:hover {
  background-color: #F5F5F5;
}

.takedown-album-pill-div {
  padding: 4px;
  border-radius: 4px;
  background: var(--bg-weak-100, #F7F7F7);
  display: flex;
  align-items: center;
}
