.analysis {
  height: auto;
  flex-grow: 1;
  margin-bottom: 56px
}

.analysis-total-box {
  width: calc((100% - 64px) / 5);
}

.analysis-summary-list {
  height: 390px;
  overflow-y: scroll;
  overflow-x: unset;
}

.analysis-summary-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.analysis-summary-list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}