.login {
  height: 100%;
}

.login .login-form {
  display: flex;
  justify-content: center;
}

.login .login-banner {
  width: 100%;
  height: auto;
  margin-bottom: 65px;
}