
.scaled-logo {
    animation: scaleUp 0.8s forwards;
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.4);
    }
}

@keyframes loadingDots {
    0% {
        content: '';
    }

    33% {
        content: '.';
    }

    66% {
        content: '..';
    }

    100% {
        content: '...';
    }
}
.dots {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 20px;
}
.dots::after {
    font-size: 14px;
    font-family: Inter;
    line-height: 20px;
    letter-spacing: -0.084px;
    color:#666;
    margin-left: 2px;
    content: '';
    animation: loadingDots 0.9s infinite;
}
