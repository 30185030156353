@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("../assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Aeonik Pro";
  src: local("Aeonik Pro"), url("../assets/fonts/AeonikPro/AeonikPro-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Aeonik Pro Bold";
  src: local("Aeonik Pro Bold"), url("../assets/fonts/AeonikPro/AeonikPro-Bold.otf") format("truetype");
}

@layer base {
  p {
    color: #18181b;
  }
  /*Light elevation*/
  .elev-xs {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .elev-sm {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 3px 5px rgba(0, 0, 0, 0.08);
  }
  .elev {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08), 0 3px 7px rgba(0, 0, 0, 0.12);
  }
  .elev-md {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08), 0 7px 15px rgba(0, 0, 0, 0.12);
  }
  .elev-lg {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12), 0 12px 28px rgba(0, 0, 0, 0.2);
  }
  .elev-xl {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12), 0 24px 54px rgba(0, 0, 0, 0.24);
  }
  /*Light bordered elevation*/
  .elev-b-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  .elev-b-sm {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.08), 0 3px 5px rgba(0, 0, 0, 0.08);
  }
  .elev-b {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.08), 0 3px 7px rgba(0, 0, 0, 0.12);
  }
  .elev-b-md {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.08), 0 7px 15px rgba(0, 0, 0, 0.12);
  }
  .elev-b-lg {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 8px rgba(0, 0, 0, 0.12), 0 12px 28px rgba(0, 0, 0, 0.2);
  }
  .elev-b-xl {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.12), 0 24px 54px rgba(0, 0, 0, 0.24);
  }
  /*Dark elevation*/
  .dark .elev-xs {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.28);
  }
  .dark .elev-sm {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.28);
  }
  .dark .elev {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.28);
  }
  .dark .elev-md {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.28);
  }
  .dark .elev-lg {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4), 0 14px 28px rgba(0, 0, 0, 0.48);
  }
  .dark .elev-xl {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4), 0 32px 64px rgba(0, 0, 0, 0.48);
  }
  /*Dark bordered elevation*/
  .dark .elev-b-xs {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.28),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.04);
  }
  .dark .elev-b-sm {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.28),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.06);
  }
  .dark .elev-b {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.28),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.08);
  }
  .dark .elev-b-md {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.28),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.08);
  }
  .dark .elev-b-lg {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4), 0px 14px 28px rgba(0, 0, 0, 0.48),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
  }
  .dark .elev-b-xl {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4), 0px 32px 64px rgba(0, 0, 0, 0.48),
      inset 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
  }

  .text-2xs {
    font-size: 10px;
    line-height: 12px;
  }
  .text-xs {
    font-size: 12px;
    line-height: 16px;
  }
  .text-sm-short {
    font-size: 14px;
    line-height: 16px;
  }
  .text-sm {
    font-size: 14px;
    line-height: 20px;
  }
  .text-base {
    font-size: 16px;
    line-height: 24px;
  }
  .text-base-short {
    font-size: 16px;
    line-height: 20px;
  }
  .text-md {
    font-size: 18px;
    line-height: 28px;
  }
  .text-lg {
    font-size: 20px;
    line-height: 28px;
  }
  .text-xl {
    font-size: 24px;
    line-height: 32px;
  }
  .text-2xl {
    font-size: 30px;
    line-height: 36px;
  }
  .text-3xl {
    font-size: 36px;
    line-height: 40px;
  }
  .text-4xl {
    font-size: 48px;
    line-height: 48px;
  }
  .text-5xl {
    font-size: 60px;
    line-height: 60px;
  }
  .text-6xl {
    font-size: 72px;
    line-height: 72px;
  }
  .text-7xl {
    font-size: 96px;
    line-height: 96px;
  }
  .text-8xl {
    font-size: 120px;
    line-height: 120px;
  }
}

@layer base {
  html,
  body {
    height: 100%;
  }
  .container {
    @apply max-w-screen-2xl mx-auto px-2 sm:px-6 lg:px-8;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .content {
    flex: 1 0 auto;
  }
}

@layer components {
  .login-form-wrapper {
    @apply flex flex-col w-full max-w-sm bg-white;
  }
  .input-decoration.error,
  input.error {
    @apply !border-red-500;
  }
  .input-decoration.error {
    @apply !border-red-500 text-red-500;
  }
  .error.message {
    @apply text-red-500 text-xs mb-3;
  }
  .forgot-password-link {
    @apply inline-flex sm:text-sm hover:text-gray-700;
  }
}

.scroll-bar-none {
  -ms-overflow-style: none; /* IE and Edge */
}

.scroll-bar-none::-webkit-scrollbar {
  display: none;
}

.Toastify__toast-body {
  align-items: start !important;
}

.Toastify__toast-container--top-right {
  top: 4.8em !important;
}

.table-row-hover {
  background: var(--bg-white-0, #FFF);
  border: 1px solid transparent;
}

.table-row-hover:hover {
  border-radius: 10px;
  border: 1px solid var(--stroke-soft-200, #e7e7e7);

  /* regular-shadow/small */
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}


.table-row-payment-hover {
  border-radius: 12px;
  border: 1px solid var(--stroke-soft-200, #F3F3F3);
  background: var(--bg-white-0, #FFF);
  box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
  margin-top: 4px;
}

.table-row-payment-hover:hover {
  border-radius: 12px;
  border: 1px solid var(--stroke-soft-200, #e7e7e7);
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}

.table-row-border-override {
  background: var(--bg-white-0, #fff);
  border-radius: 10px;
  border: 1px solid var(--stroke-soft-200, #e7e7e7);
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}

.custom-toast .Toastify__toast-icon {
  width: 44px;
  height: 44px;
  margin-inline-end: 12px !important;
}