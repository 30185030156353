.catalog {
  height: auto;
  flex-grow: 1;
}
/*################ Release Detail Page #################*/
.release-detail {
  height: auto;
  flex-grow: 1;
  padding-top: 56px;
  background-color: white;
}

.catalog-details {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
}
/*################ Track Detail Page #################*/
.track-detail {
  height: auto;
  flex-grow: 1;
  padding-top: 56px;
  padding-bottom: 56px;
  background-color: white;
}

.selected-r-tab {
  padding: 4px 16px;
  margin: 12px 6px;
  border-radius: 20px;
  color: white;
}

.r-tab {
  padding: 4px 16px;
  margin: 12px 6px;
  border-radius: 20px;
}

.hover-div {
  transition: background-color 0.3s ease; /* Smooth transition effect */
}

.hover-div:hover {
  background-color: rgba(162, 200, 208, 0.17); /* Background color on hover */
  cursor: pointer;
}

.text-nowrap {
  text-wrap: nowrap;
}

.asset-registry-title {
  font-family: "Aeonik Pro";
}

.asset-registry-span {
  font-family: "Inter";
}

.owner-card {
  box-shadow: 0px 1px 2px 0px rgba(228, 229, 231, 0.24);
}

.owner-card:hover {
  box-shadow: 0px 2px 4px 0px rgba(27, 28, 29, 0.04);
}