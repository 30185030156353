.catalog {
  height: auto;
  flex-grow: 1;
}
/*################ Release Detail Page #################*/
.release-detail {
  height: auto;
  flex-grow: 1;
  padding-top: 56px;
  background-color: white;
}

.catalog-details {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
}
/*################ Track Detail Page #################*/
.track-detail {
  height: auto;
  flex-grow: 1;
  padding-top: 56px;
  padding-bottom: 56px;
  background-color: white;
}

.upload-menu-button {
  box-shadow: rgba(17, 17, 17, 0.04) 0px 0px 0px 1px, rgba(17, 17, 17, 0.04) 0px 1px 1px 0px
}