.invitation {
    height: 100%;
}

.invitation .invitation-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.invitation .invitation-banner {
    width: 100%;
    height: auto;
    margin-bottom: 65px;
}

.invitation .invitation-wrapper .invitation-container {
    display: flex;
    flex-direction: row;
}